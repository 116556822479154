// src/App.js
import React, { useState } from "react";
import "./App.css";

import lyonImage from "./assets/lyon.png";

// Sample data for top 10 croissants in Lyon
const croissantRankings = [
  {
    rank: 1,
    name: "Délices des Sens",
    address: "12 Bd des Brotteaux, 69006 Lyon",
    gmaplink: "https://maps.app.goo.gl/eaDcpr2KQo9ceFBC7",
    description:
      "Le croissant de Délices des Sens est une pépite gastronomique. Son visuel est à couper le souffle, avec une dorure parfaite et un feuilletage qui donne envie de l’admirer autant que de le déguster. En bouche, il se distingue par une texture équilibrée et un goût riche et raffiné. Le beurre est présent sans être écrasant, et chaque bouchée révèle un savoir-faire d'exception. Ce croissant est un véritable plaisir pour les amateurs de viennoiseries haut de gamme.",
    prix: 1.3,
  },
  {
    rank: 2,
    name: "Pralus La Boulangerie, Quai Saint-Antoine",
    address: "18 Quai Saint-Antoine, 69002 Lyon",
    gmaplink: "https://maps.app.goo.gl/KfiAxCK6cVxfPNon8",
    description:
      "Chez Pralus, le croissant brille par son visuel spectaculaire, comparable à un lever de soleil grâce à sa dorure éclatante et son feuilletage bien travaillé. Cependant, en bouche, il manque légèrement de richesse en beurre, ce qui le rend un peu sec et fade. Malgré ce défaut, il demeure supérieur à beaucoup de croissants industriels et offre une expérience visuelle et gustative plaisante.",
    prix: 1.4,
  },
  {
    rank: 3,
    name: 'Boulangerie Pâtisserie "L\' Atelier Cours Albert Thomas"',
    address: "135 Cr Albert Thomas, 69003 Lyon",
    gmaplink: "https://maps.app.goo.gl/6wk7Nh6bc1zW7chv7",
    description:
      "Le croissant de L'Atelier Cours Albert Thomas est une belle surprise. Bien que son aspect soit plutôt simple et donne l’impression d’un produit sec, il révèle en bouche une aération bien maîtrisée et un goût agréable de beurre. Sans être exceptionnel, il offre une expérience classique et équilibrée qui plaira aux amateurs de viennoiseries traditionnelles.",
    prix: 1.2,
  },
  {
    rank: 4,
    name: "Maison Chadli",
    address: "29 Rue Masséna, 69006 Lyon",
    gmaplink:
      "https://www.google.fr/maps/place/Maison+chadli/@45.7702201,4.8494036,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ea8d02655555:0xf4997c2b052b36e0!8m2!3d45.7702202!4d4.8542745!16s%2Fg%2F11j8lmxg62?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Le croissant de Maison Chadli offre une richesse en beurre qui ravira les amateurs de gourmandise. Cependant, bien qu'il soit généreux en saveurs, il souffre d'un manque d'aération qui affecte sa légèreté une fois en main. Son visuel peut également être amélioré pour correspondre au goût agréable qu'il propose.",
    prix: 1.15,
  },
  {
    rank: 5,
    name: "Boulangerie Pic et Mie",
    address: "41 Cr Albert Thomas, 69003 Lyon",
    gmaplink:
      "https://www.google.com/maps/place/Boulangerie+Pic+%26+Mie/@45.7480145,4.8621161,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ebf2c698297d:0xf5ea94140c0944b5!8m2!3d45.7480145!4d4.864691!16s%2Fg%2F11rvhmb0m1?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Malgré un visuel peu engageant, le croissant de Pic et Mie réserve une agréable surprise en bouche. Son goût est satisfaisant et bien équilibré, bien qu'il ne se démarque pas particulièrement. C'est un choix sûr pour ceux qui recherchent un croissant correct sans trop d’attentes.",
    prix: 1.2,
  },
  {
    rank: 6,
    name: "Le Fournil de l'Artisan",
    address: "152 Cr Lafayette, 69003 Lyon",
    gmaplink: "https://maps.app.goo.gl/hduWaeHExenWpmwA6",
    description:
      "Le Fournil de l'Artisan propose un croissant simple mais efficace à un prix imbattable. Bien qu'il ne soit pas très esthétique et souffre d'un manque d'aération, il reste agréable en bouche avec un goût correct et une texture acceptable pour son tarif très compétitif.",
    prix: 0.95,
  },
  {
    rank: 7,
    name: "ALMA par Marvin Brandao Pâtisserie-Boulangerie",
    address: "17 Rue Bugeaud, 69006 Lyon",
    gmaplink:
      "https://www.google.fr/maps/place/ALMA+par+Marvin+Brandao+P%C3%A2tisserie-Boulangerie/@45.7649525,4.8478387,15z/data=!4m10!1m2!2m1!1sAlma+boulangerie!3m6!1s0x47f4eb16967aa0af:0x53b2bd732196a666!8m2!3d45.7659747!4d4.8432782!15sChBBbG1hIGJvdWxhbmdlcmllWhIiEGFsbWEgYm91bGFuZ2VyaWWSAQpwYXRpc3Nlcmll4AEA!16s%2Fg%2F11jt0gcbz2?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Chez ALMA par Marvin Brandao, le croissant séduit par son aspect visuel soigné et élégant. Cependant, il manque cruellement de saveur en bouche à cause d'une faible teneur en beurre et d'une texture sèche. Ce croissant conviendra davantage à ceux qui recherchent un produit esthétique plutôt que gustatif.",
    prix: 1.2,
  },
  {
    rank: 8,
    name: "La Minaudière - Cellerier Presqu'île",
    address: "5 Rue de Brest, 69002 Lyon",
    gmaplink: "https://maps.app.goo.gl/wR2iu7DBkewk4S8s9",
    description:
      "La Minaudière propose un croissant correct mais sans éclat particulier. Bien que son goût soit acceptable avec une légère pointe de beurre, il souffre d'une texture un peu sèche et manque du raffinement attendu dans une boulangerie haut de gamme. Une option honnête mais pas mémorable.",
    prix: 1.2,
  },
  {
    rank: 9,
    name: "Boulangerie Chez Jules Zola",
    address: "30 Cr Émile-Zola, 69100 Villeurbanne",
    gmaplink:
      "https://www.google.fr/maps/place/Boulangerie+Chez+Jules+Zola/@45.7649516,4.8478387,15z/data=!4m10!1m2!2m1!1sjules+patisserie!3m6!1s0x47f4ea851c700001:0x5092f03d162f811e!8m2!3d45.7704971!4d4.8652273!15sChBqdWxlcyBwYXRpc3NlcmllWhIiEGp1bGVzIHBhdGlzc2VyaWWSAQZiYWtlcnngAQA!16s%2Fg%2F11g6j7bxcf?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Le croissant de Chez Jules Zola se positionne comme un choix tout juste correct. Avec une texture sèche et une faible richesse en beurre, il peine à se démarquer dans une ville où la concurrence est rude. Son visuel sauve quelque peu la prestation globale.",
    prix: 1.3,
  },
];

function App() {
  const [activeTab, setActiveTab] = useState("ranking");
  const [selectedBakery, setSelectedBakery] = useState(null);

  return (
    <div className="App">
      {/* Banner with title and croissant image */}
      <header className="App-header">
        {/* <h1>TITRE</h1> */}
        <h1>Le Meilleur Croissant</h1>
      </header>

      {/* Tabs */}
      <div className="tabs">
        <button onClick={() => setActiveTab("ranking")}>Lyon 2025</button>
        <button onClick={() => setActiveTab("about")}>A propos</button>
      </div>

      {/* Tab Content */}
      {activeTab === "ranking" && (
        <section className="ranking">
          <div className="flex-column">
            <div className="flex-row">
              <h2>Meilleurs Croissants à Lyon (2025)</h2>
              <img src={lyonImage} alt="Lyon" className="lyon-image" />
            </div>
          </div>

        <section className="description">
            <p>
            Découvrez notre classement des meilleurs croissants au beurre de Lyon pour 2025, 
            un guide exhaustif basé sur une dégustation minutieuse dans plus de 20 boulangeries 
            locales. 
            </p>
            <p>
            Chaque croissant a été évalué selon des critères professionnels : qualité et présence du 
            beurre, texture, aspect visuel et harmonie des saveurs. Dans une ville où 
            la gastronomie est un art, nous mettons 
            régulièrement à jour ce classement pour refléter les nouvelles découvertes et les 
            boulangeries qui élèvent le croissant au rang d'excellence.
            </p>
          </section>

          <h2>Classement:</h2>
          <ul className="croissant-list">
            {croissantRankings.map((bakery) => (
              <React.Fragment key={bakery.rank}>
                <li className="croissant-item">
                  <div className="flex-row croissant-info">
                    <span className="rank">#{bakery.rank}</span>
                    <strong>{bakery.name}</strong>
                  </div>
                  {/* <span>{bakery.address}</span> */}
                  <a
                    href={bakery.gmaplink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="address"
                  >
                    <u>{bakery.address}</u>
                  </a>
                  <button onClick={() => setSelectedBakery(bakery)}>
                    +
                  </button>
                </li>
                <hr className="separator" /> {/* Separator between items */}
              </React.Fragment>
            ))}
          </ul>
        </section>
      )}

      {/* Dialog Box for Bakery Description */}
      {selectedBakery && (
        <div className="dialog">
          <div className="dialog-content">
            <h3>{selectedBakery.name}</h3>
            <p>{selectedBakery.description} Le croissant coute {selectedBakery.prix}€.</p>
            <button onClick={() => setSelectedBakery(null)}>Fermer</button>
          </div>
        </div>
      )}

      {activeTab === "about" && (
        <section className="description">
          <p>
            <b>
              Bienvenue sur votre guide ultime pour découvrir les meilleurs
              croissants de la capitale mondiale de la gastronomie !
            </b>
          </p>
          <p>
            Saviez-vous que c'est en 1935 que le célèbre critique culinaire
            Curnonsky a couronné Lyon du prestigieux titre de "capitale mondiale
            de la gastronomie" ? Depuis, cette ville emblématique continue de
            briller au firmament de la haute cuisine française, influencée par
            des figures légendaires comme Paul Bocuse et les mères lyonnaises.
            Mais ici, dans la ville où chaque repas est une fête, il n'y a pas
            que les bouchons qui méritent notre attention...
            <b> Les croissants aussi sont de véritables œuvres d'art !</b>
          </p>
          <p>
            Malheureusement, l'art du <b>vrai croissant</b> est en danger. En
            2008, on estimait que <b>40 % des croissants</b> vendus en France
            étaient fabriqués à partir de <b>pâte surgelée</b>. Pourtant, un
            croissant, aussi simple que cela puisse paraître, ne doit pas être
            banalisé ou artificiellement sublimé. Tout se retrouve dans la
            pureté des ingrédients : un bon beurre, une farine de qualité, et un
            savoir-faire artisanal qui ne peut être remplacé par des raccourcis
            industriels. Cet équilibre parfait est ce qui fait du croissant une
            expérience unique et précieuse.
          </p>
          <p>
            C'est pourquoi notre équipe d'experts sillonne les rues de Lyon,
            armée d'un palais affûté et d'une passion pour cette viennoiserie
            légendaire. Nous testons, comparons et classons les croissants des
            boulangeries aux quatre coins de la ville pour vous offrir une
            sélection des meilleurs. Chez nous, tout est pris en compte :
            l'aspect visuel (ce feuilletage doré irrésistible), le goût (bien
            sûr !), l'expérience globale (la première bouchée doit être
            magique), et surtout, la qualité du beurre - cet ingrédient clé qui
            fait toute la différence.
          </p>
          <p>
            Et parce que Lyon est une ville en constante évolution
            gastronomique, nos classements sont régulièrement mis à jour pour
            refléter les nouvelles pépites du moment. 🥐✨
          </p>
        </section>
      )}

      <div style={{ height: "30px" }}></div>
    </div>
  );
}

export default App;
